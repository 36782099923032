import React from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'
import { StyledH1, StyledP } from '../components/Shared'

import oops from '../images/oops.svg'

let Oops = styled('img')`
  max-width: 300px;
`

const NotFoundPage = () => (
  <Layout>
    <Oops src={oops} alt='oops' />
    <StyledH1>NOT FOUND</StyledH1>
    <StyledP>You just hit a route that doesn&#39;t exist...</StyledP>
    <StyledP style={{marginBottom: '200px'}}>Use the navagation above to go where you want</StyledP>
  </Layout>
)

export default NotFoundPage
